import React, { useContext, useRef, useState, useEffect } from 'react'
import { AppContext } from 'contexts/AppContext';
import { InfinitySpin } from 'react-loader-spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export default ({ baseUrl }) => {
    const { context, setContext } = useContext(AppContext);

    function openModalSingleInsert() {
        let html = (
            <>
                <h4>Single Insert</h4><br />
                <div className='row'>
                    <div className='col-sm-4'>
                        <label>Email:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>Password:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>First Name:</label>
                        <input className='form-control' type='password' />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-sm-4'>
                        <label>Last Name:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>ZIP Code:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>Phone Number:</label>
                        <input className='form-control' type='password' />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-sm-4'>
                        <label>Show #1:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>Show #2:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>Show #3:</label>
                        <input className='form-control' type='password' />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-sm-4'>
                        <label>Show #4:</label>
                        <input className='form-control' />
                    </div>
                    <div className='col-sm-4'>
                        <label>Show #5:</label>
                        <input className='form-control' />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button style={{ marginRight: '10px' }} className='btn btn-secondary'>
                            {'Show Password'}
                        </button>
                        <button className='btn btn-primary' >Salvar</button>
                    </div>
                </div>
            </>
        );

        MySwal.fire({
            html: html,
            width: '90vw',
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-list"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Insert Data</h1>
            </div>

            <div className='d-sm-flex justify-content-end align-items-center mb-4'>
                <button className='btn btn-primary mr-2' onClick={() => openModalSingleInsert()}>Single Insert</button>
                <button className='btn btn-secondary'>CSV Bulk Insert</button>
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: '0' }}>
                        <div className="card-body">
                            <div className="table-responsive">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
