import React, { useContext, useEffect, useRef, useState } from 'react'
import Card from '../Layouts/Card'
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { AppContext } from 'contexts/AppContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export default ({ baseUrl }) => {
    const [data, setData] = useState([]);
    const { context, setContext } = useContext(AppContext);

    const [runningTasksCount, setRunningTasksCount] = useState(0);
    const [queuedTasksCount, setQueuedTasksCount] = useState(0);

    const inputNewLimits = useRef();

    const ExpandedComponent = ({ data }) => {
        return (<table>
            <tbody>
                <tr style={{ backgroundColor: '#F3F3F3' }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: '20px 20px' }}>Running: {data.runningLines}</th>
                </tr>
                <tr style={{ backgroundColor: '#F3F3F3' }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: '20px 20px' }}>Queued: {data.queuedLines}</th>
                </tr>
            </tbody>
        </table>);
    };

    var groupBy = function (xs) {
        let runningTasks = 0, queuedTasks = 0;

        let list = xs.reduce(function (prev, curr) {
            let getEl = prev.find(x => x.xmlName == curr.xmlName && x.sheetId == curr.sheetId
                && x.sheetTabName == curr.sheetTabName);

            let isRunning = Boolean(curr.isRunning), isFinished = Boolean(curr.isFinished), isKilled = Boolean(curr.isKilled);

            if (!getEl || getEl.length == 0) {
                getEl = curr;

                getEl.tasksCount = 0;
                getEl.tasksQueuedCount = 0;
                getEl.runningLines = '';
                getEl.queuedLines = '';

                prev.push(getEl);
            } else {
                getEl.qtt += curr.qtt;
            }


            if (isRunning && !isFinished && !isKilled) {
                getEl.tasksCount += parseInt(curr.qtt);
                getEl.runningLines += curr.sheetLines;
                runningTasks += curr.qtt;
            } else if (!isRunning && !isFinished && !isKilled) {
                getEl.tasksQueuedCount += parseInt(curr.qtt);
                getEl.queuedLines += curr.sheetLines;
                queuedTasks += curr.qtt;
            }

            return prev;

        }, []);

        setQueuedTasksCount(queuedTasks);
        setRunningTasksCount(runningTasks);

        return list;

    };

    useEffect(() => {
        fetch(baseUrl + 'tasks', { method: 'POST', headers: { 'x-app-token': context.token } })
            .then(result => result.json())
            .then(result => {
                if (!data || data != result.data)
                    setData(groupBy(result.data));

                setContext(old => { return { ...old, isLoading: false } });
            }).catch(() => {
                setContext(old => { return { ...old, isLoading: false } });
            });
    }, [])


    const columns = [
        {
            name: 'XML Name',
            selector: row => row.xmlName,
            sortable: true
        },
        {
            name: 'Tab Name',
            selector: row => row.sheetTabName,
            sortable: true
        },
        {
            name: 'Sheet ID',
            selector: row => row.sheetId,
            sortable: true
        },
        {
            name: 'Qtt Running',
            selector: row => row.tasksCount,
            sortable: true
        },
        {
            name: 'Qtt Queued',
            selector: row => row.tasksQueuedCount,
            sortable: true
        },
    ];

    const modalChangeThreadsLimit = (oNewLimit) => {
        setContext(old => { return { ...old, isLoading: true} });


        fetch(baseUrl + 'threads/limit',
            {
                method: 'GET',
                headers: {
                    'x-app-token': context.token,
                    'Content-Type': 'application/json'
                }
            }).then(result => {
                if (result.status == 200)
                    return result.json();
                else
                    throw 'Error getting the current threads limit value';
            }).then(result => {
                let defaultLimit = oNewLimit;

                if (!defaultLimit && result?.data?.length > 0)
                    defaultLimit = result.data[0].value;

                let html = (<>
                    <div className='col-12'>
                        <label >New threads limit:</label><br></br>
                        <input style={{ textAlign: 'center' }} className='form-control'
                            defaultValue={defaultLimit ?? 0} name='txtNewThreadsLimit' ref={inputNewLimits}
                            onKeyDown={(event) => {
                                if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className='col-12 mt-4'>
                        <button className='btn btn-danger' onClick={() => MySwal.close()}>Cancel</button>
                        <button className='btn btn-success ml-4' onClick={() => changeThreadsLimit()}>Confirm</button>
                    </div>
                </>);

                MySwal.fire({
                    html: html,
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false
                });
            }).catch((ex) => {
                MySwal.fire({
                    icon: 'error',
                    html: ex
                });
            })
            .finally(() => {
            setContext(old => { return { ...old, isLoading: false} });
            });
    };

    const changeThreadsLimit = () => {
            setContext(old => { return { ...old, isLoading: true } });
        MySwal.close();

        let newLimit = inputNewLimits.current.value;

        fetch(baseUrl + 'threads/limit', {
            method: 'PUT',
            headers: {
                'x-app-token': context.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newLimit: newLimit })
        }).then(result => {
            if (result.status == 200) {
                MySwal.fire({
                    icon: 'success',
                    html: 'New threads limit has set successfully!'
                });
            } else {
                throw 'Error to set the new threads limit';
            }
        }).catch((ex) => {
            MySwal.fire({
                icon: 'error',
                html: ex
            });
        }).finally(() => {
            setContext(old => { return { ...old, isLoading: false } });
        });
    };

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fas fa-fw fa-tachometer-alt"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">BAS Tasks</h1>
                </div>

                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Card name="Running Tasks" quantity={runningTasksCount} icon="calendar" color="warning" />
                    <Card name="Queued Tasks" quantity={queuedTasksCount} icon="calendar" color="success" />
                </div>
                <div className="row">
                    <div className="card shadow mb-4">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className='col-12' style={{ textAlign: 'center' }} >
                                <button onClick={() => modalChangeThreadsLimit()} className='btn btn-primary'>Change threads limit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-success">Analytical Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
