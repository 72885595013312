import React, { useContext, useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Card from '../Layouts/Card'
import { InfinitySpin } from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { AppContext } from 'contexts/AppContext';

const MySwal = withReactContent(Swal)

export default ({ baseUrl }) => {
    const [slotsNumber, setSlotsNumber] = useState(0);
    const [remainingNumbers, setRemainingNumbers] = useState(0);

    const [isSlotsRotationRunning, setIsSlotsRotationRunning] = useState(false);
    const [isSmsCheckerRunning, setIsSmsCheckerRunning] = useState(false);
    const [isSearchingSmsBySlot, setIsSearchingSmsBySlot] = useState(false);

    const [modemFilter, setModemFilter] = useState('');
    const [portFilter, setPortFilter] = useState('');
    const [slotFilter, setSlotFilter] = useState('');

    const [smsData, setSmsData] = useState([]);

    const { context, setContext } = useContext(AppContext);

    const modem = useRef();
    const slot = useRef();
    const port = useRef();

    useEffect(() => {
        refreshData();

        setInterval(() => {
            refreshData();
        }, 10_000);

        MySwal.close();
    }, []);

    const refreshData = () => {
        fetch(baseUrl + 'slots', { method: 'GET', headers: { 'x-app-token': context.token } })
            .then(result => result.json())
            .then(result => {

                if (!result || !result.success || !result.data)
                    return;

                setSlotsNumber(result.data.rotationNumber);

                setRemainingNumbers(result.data.rotationNumber !== 0 ? (8 - result.data.rotationNumber) : 0);

                setIsSlotsRotationRunning(result.data.isRunningRotation);
                setIsSmsCheckerRunning(result.data.isRunningSmsChecker);

                setContext(old => { return { ...old, isLoading: false } });
            }).catch(() => {
                setContext(old => { return { ...old, isLoading: false } });
            });
    };

    const sendStartWorker = (workerName) => {
        let options = {
            method: 'POST',
            headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ workerName })
        }

        fetch(baseUrl + 'slots', options)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    text: 'Run command send successfully for ' + workerName
                });
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Error sending RUN command for ' + workerName + ' | ' + error
                });
            })
    };

    const modalSwitchSinglePort = (oModem, oPort, oSlot) => {

        let html = (<>
            <div className='col-12'>
                <label>Modem:</label>
                <select defaultValue={oModem} className='form-control' style={{ textAlign: 'center' }} ref={modem}>
                    <option value=''>Select..</option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                    <option value='E'>E</option>
                    <option value='F'>F</option>
                    <option value='G'>G</option>
                    <option value='H'>H</option>
                    <option value='I'>I</option>
                    <option value='J'>J</option>
                </select>
            </div>
            <div className='col-12 mt-4'>
                <label>Port (1-64):</label>
                <input defaultValue={oPort} style={{ textAlign: 'center' }} className='form-control' type='text' ref={port} />
            </div>
            <div className='col-12 mt-4'>
                <label>Slot (1-8):</label>
                <input defaultValue={oSlot} style={{ textAlign: 'center' }} className='form-control' type='text' ref={slot} />
            </div>
            <div className='col-12 mt-4'>
                <button className='btn btn-danger' onClick={() => MySwal.close()}>Cancel</button>
                <button className='btn btn-success ml-4' onClick={() => switchSinglePort()}>Switch</button>
            </div>
        </>);

        MySwal.fire({
            html,
            width: '30vw',
            showCancelButton: false,
            showConfirmButton: false
        });
    };

    const switchSinglePort = (isPromise, oModem, oPort, oSlot) => {
        if (!isPromise) {

            MySwal.close();
            setContext(old => { return { ...old, isLoading: true } });


            let modemVal = oModem ?? modem.current.value;
            let slotVal = oSlot ?? slot.current.value;
            let portVal = oPort ?? port.current.value;

            let modemUrl = '', error = '';

            switch (modemVal) {
                case 'A':
                    modemUrl = `http://47.89.155.173:50002/`;
                    break;
                case 'B':
                    modemUrl = `http://47.89.155.173:50004/`;
                    break;
                case 'C':
                    modemUrl = `http://47.89.155.173:50006/`;
                    break;
                case 'D':
                    modemUrl = `http://47.89.155.173:50008/`;
                    break;
                case 'E':
                    modemUrl = `http://47.89.155.173:50010/`;
                    break;
                case 'F':
                    modemUrl = `http://47.89.155.173:50012/`;
                    break;
                case 'G':
                    modemUrl = `http://47.89.155.173:50016/`;
                    break;
                case 'H':
                    modemUrl = `http://47.89.155.173:50018/`;
                    break;
                case 'I':
                    modemUrl = `http://47.89.155.173:50020/`;
                    break;
                case 'J':
                    modemUrl = `http://47.89.155.173:50022/`;
                    break;
                default:
                    error += '- Modem is required <br/>';
                    break;
            }

            if (!slotVal)
                error += '- Slot is required <br/>';

            if (isNaN(parseInt(slotVal)))
                error += '- Slot must be a number <br/>';
            else if (parseInt(slotVal) < 1 || parseInt(slotVal) > 8)
                error += '- Slot must be between 1 and 8 <br/>';

            if (!portVal)
                error += '- Port is required <br/>';

            if (isNaN(parseInt(portVal)))
                error += '- Port must be a number <br/>';
            else if (parseInt(portVal) < 1 || parseInt(portVal) > 64)
                error += '- Port must be between 1 and 64 <br/>';

            if (!error) {
                console.log('success')

                fetch(baseUrl + `switch-ejoin`,
                    {
                        method: 'POST',
                        headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
                        body: JSON.stringify({ modem: modemVal, port: portVal, slot: slotVal })
                    })
                    .then(result => {
                        if (result.status == 200) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Switch command has sent successfully!'
                            });
                        } else {
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error sending the switch command to modem ' + modemVal
                            });
                        }
                    })
                    .catch(() => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error sending the switch command to modem ' + modemVal
                        });
                    })
                    .finally(() => setContext(old => { return { ...old, isLoading: false } }));
            }
            else {
                MySwal.fire({
                    icon: 'error',
                    title: error
                }).then(() => {
                    modalSwitchSinglePort(modemVal, portVal, slotVal);
                });

                setContext(old => { return { ...old, isLoading: false } });
            }
        }
        else {
            return new Promise((resolve, reject) => {

                let modemVal = oModem ?? modem.current.value;
                let slotVal = oSlot ?? slot.current.value;
                let portVal = oPort ?? port.current.value;

                let modemUrl = '', error = '';

                switch (modemVal) {
                    case 'A':
                        modemUrl = `http://47.89.155.173:50002/`;
                        break;
                    case 'B':
                        modemUrl = `http://47.89.155.173:50004/`;
                        break;
                    case 'C':
                        modemUrl = `http://47.89.155.173:50006/`;
                        break;
                    case 'D':
                        modemUrl = `http://47.89.155.173:50008/`;
                        break;
                    case 'E':
                        modemUrl = `http://47.89.155.173:50010/`;
                        break;
                    case 'F':
                        modemUrl = `http://47.89.155.173:50012/`;
                        break;
                    case 'G':
                        modemUrl = `http://47.89.155.173:50016/`;
                        break;
                    case 'H':
                        modemUrl = `http://47.89.155.173:50018/`;
                        break;
                    case 'I':
                        modemUrl = `http://47.89.155.173:50020/`;
                        break;
                    case 'J':
                        modemUrl = `http://47.89.155.173:50022/`;
                        break;
                    default:
                        error += '- Modem is required <br/>';
                        break;
                }

                if (!slotVal)
                    error += '- Slot is required <br/>';

                if (isNaN(parseInt(slotVal)))
                    error += '- Slot must be a number <br/>';
                else if (parseInt(slotVal) < 1 || parseInt(slotVal) > 8)
                    error += '- Slot must be between 1 and 8 <br/>';

                if (!portVal)
                    error += '- Port is required <br/>';

                if (isNaN(parseInt(portVal)))
                    error += '- Port must be a number <br/>';
                else if (parseInt(portVal) < 1 || parseInt(portVal) > 64)
                    error += '- Port must be between 1 and 64 <br/>';

                if (!error) {
                    fetch(baseUrl + `switch-ejoin`,
                        {
                            method: 'POST',
                            headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
                            body: JSON.stringify({ modem: modemVal, port: portVal, slot: slotVal })
                        })
                        .then(result => {
                            if (result.status == 200) {
                                return resolve('Switch command has sent successfully!');
                            } else {

                                return reject('Error sending the switch command to modem ' + modemVal);
                            }
                        })
                        .catch(() => {
                            return reject('Error sending the switch command to modem ' + modemVal);
                        });
                }
                else {
                    return reject(error);
                }
            });
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);

        Swal.fire({
            toast: true,
            icon: 'success',
            text: 'Text copied to clipboard!',
            timer: 2000,
            position: 'top-right',
            showCloseButton: false,
            showConfirmButton: false
        });
    };

    const getLastSmsEjoin = (isSearchBySlot) => {
        setContext(old => { return { ...old, isLoading: true } });


        if (!isSearchBySlot) {

            if (!modemFilter || !['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].includes(modemFilter)) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Invalid modem'
                });
                setContext(old => { return { ...old, isLoading: false } });

                return;
            }

            if (!portFilter || isNaN(parseInt(portFilter)) || portFilter < 1 || portFilter > 64) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Invalid port'
                });
                setContext(old => { return { ...old, isLoading: false } });

                return;
            }

            fetch(baseUrl + `ejoin/sms?modem=${modemFilter}&port=${portFilter}&limit=10`,
                {
                    headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' }
                })
                .then(result => {
                    if (result.status == 200) {
                        return result.json();
                    } else {
                        throw 'Error getting the last EJOIN messages';
                    }
                })
                .then(result => {
                    if (result && !!result.success && !!result.data && result.data.length > 0)
                        setSmsData(result.data);
                })
                .catch(error => {
                    MySwal.fire({
                        icon: 'error',
                        html: 'Error getting the last EJOIN messages'
                    });
                })
                .finally(() => setContext(old => { return { ...old, isLoading: false } }));
        } else {

            if (!modemFilter || !['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].includes(modemFilter)) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Invalid modem'
                });
                setContext(old => { return { ...old, isLoading: false } });

                return;
            }

            if (!portFilter || isNaN(parseInt(portFilter)) || portFilter < 1 || portFilter > 64) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Invalid port'
                });
                setContext(old => { return { ...old, isLoading: false } });

                return;
            }

            if (!slotFilter || isNaN(parseInt(slotFilter)) || slotFilter < 1 || slotFilter > 8) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Invalid slot'
                });
                setContext(old => { return { ...old, isLoading: false } });

                return;
            }

            setIsSearchingSmsBySlot(true);

            switchSinglePort(true, modemFilter, portFilter, slotFilter)
                .then(result => {
                    setTimeout(() => {
                        fetch(baseUrl + `ejoin/sms?modem=${modemFilter}&port=${portFilter}&slot=${slotFilter}&limit=10`,
                            {
                                headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' }
                            })
                            .then(result => {
                                if (result.status == 200) {
                                    return result.json();
                                } else {
                                    throw 'Error getting the last EJOIN messages';
                                }
                            })
                            .then(result => {
                                if (result && !!result.success && !!result.data && result.data.length > 0)
                                    setSmsData(result.data);
                            })
                            .catch(error => {
                                MySwal.fire({
                                    icon: 'error',
                                    html: 'Error getting the last EJOIN messages'
                                });
                            })
                            .finally(() => {
                                setContext(old => { return { ...old, isLoading: false } });
                                setIsSearchingSmsBySlot(false);

                            });
                    }, 60_000);
                })
                .catch(error => {
                    MySwal.fire({
                        icon: 'error',
                        html: 'Error switching the port on EJOIN platform'
                    });

                    setIsSearchingSmsBySlot(false);
                    setContext(old => { return { ...old, isLoading: false } });
                });
        }
    };

    const ExpandedComponent = ({ data }) => {
        return (<table style={{ width: '100%' }}>
            <tbody>
                <tr onClick={() => copyToClipboard(data.message)} style={{ cursor: 'pointer', backgroundColor: '#F3F3F3' }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: '20px 20px' }}>Full Message: {data.message}</th>
                </tr>
            </tbody>
        </table>);
    };

    let columns = [
        {
            name: 'Date',
            selector: row => new Date(row.datetime).toLocaleString(),
            sortable: true
        },
        {
            name: 'Sender',
            selector: row => row.sender,
            sortable: true
        },
        {
            name: 'Receiver',
            selector: row => row.receiver,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => {
                let modem = (row.port ?? '').replace(/\d/g, '');
                let port = (row.port ?? '').replace(/\D/g, '');

                return ''.concat(modem, port);
            },
            sortable: true
        },
        {
            name: 'Slot',
            selector: row => row.slot,
            sortable: true
        },
        {
            name: 'Message',
            selector: row => row.message?.substr(0, 30),
            sortable: true
        }
    ];

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center mb-4">
                    <i className="fa fa-toggle-on"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Slots Rotation</h1>
                </div>

                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Card name="Current Rotation Number" quantity={slotsNumber} icon="hashtag" color="success" />
                    <Card name="Remaining Rotations" quantity={remainingNumbers} icon="list-check" color="warning" />
                    <Card name="Is Slots Rotation Running?" quantity={!isSlotsRotationRunning ? 'No' : 'Yes'} icon="question" color="primary" />
                </div>
                <div className="row">
                    <div className="card shadow mb-4">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className='col-6' style={{ textAlign: 'center' }} >
                                {!isSlotsRotationRunning ?
                                    (<button onClick={() => sendStartWorker('SlotsSwitcher')} className='btn btn-primary'>Start Slots Rotation</button>) :
                                    (<button className='btn btn-danger' disabled><i className="fa fa-ban"></i> Slots Rotation Running</button>)
                                }
                            </div>
                            <div className='col-6' style={{ textAlign: 'center' }}>
                                <button onClick={() => modalSwitchSinglePort()} className='btn btn-primary'>Switch single port</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '2vh' }}>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className='col-12' style={{ textAlign: 'left' }} >
                                    <h5>Last 10 Messages</h5>
                                </div>
                                <div className='row  mt-4 mb-4'>
                                    <div className='col-12'>
                                        Search by Port
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-1'></div>
                                    <div className='col-4'>
                                        <label>MODEM (A-J)</label>
                                        <select onChange={(e) => setModemFilter(e.currentTarget.value)}
                                            className='form-control mt-2' style={{ display: 'inline-block' }}>
                                            <option value="">Select...</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                            <option value="G">G</option>
                                            <option value="H">H</option>
                                            <option value="I">I</option>
                                            <option value="J">J</option>
                                        </select>
                                    </div>
                                    <div className='col-4'>
                                        <label>PORT (1-64) </label>
                                        <input type='text' onChange={(e) => setPortFilter(e.currentTarget.value)}
                                            className='form-control mt-2' style={{ display: 'inline-block' }} />
                                    </div>
                                    <div className='col-2' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        {
                                            isSearchingSmsBySlot ?
                                                (<button className='btn btn-primary' disabled={true}>
                                                    Searching...
                                                </button>) :
                                                (<button onClick={() => getLastSmsEjoin(false)} className='btn btn-primary'>
                                                    <i className='fa fa-search'></i> &nbsp;Search
                                                </button>)
                                        }

                                    </div>
                                </div>
                                <hr className='mt-5' />
                                <div className='row mt-4 mb-4'>
                                    <div className='col-12'>
                                        Search by Slot
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label>MODEM (A-J)</label>
                                        <select onChange={(e) => setModemFilter(e.currentTarget.value)}
                                            className='form-control mt-2' style={{ display: 'inline-block' }}>
                                            <option value="">Select...</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                            <option value="G">G</option>
                                            <option value="H">H</option>
                                            <option value="I">I</option>
                                            <option value="J">J</option>
                                        </select>
                                    </div>
                                    <div className='col-3'>
                                        <label>PORT (1-64) </label>
                                        <input type='text' onChange={(e) => setPortFilter(e.currentTarget.value)}
                                            className='form-control mt-2' style={{ display: 'inline-block' }} />
                                    </div>
                                    <div className='col-2'>
                                        <label>SLOT (1-8) </label>
                                        <input type='text'
                                            onChange={(e) => setSlotFilter(e.currentTarget.value)}
                                            className='form-control mt-2' style={{ display: 'inline-block' }} />
                                    </div>
                                    <div className='col-2' style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        {
                                            isSearchingSmsBySlot ?
                                                (<button className='btn btn-primary' disabled={true}>
                                                    Searching...
                                                </button>) :
                                                (<button onClick={() => getLastSmsEjoin(true)} className='btn btn-primary'>
                                                    <i className='fa fa-search'></i> &nbsp;Search
                                                </button>)
                                        }
                                    </div>
                                </div>
                                <hr className='mt-5' style={{ background: 'gray' }} />
                                <div className='row mt-4'>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={smsData}
                                            expandableRows
                                            expandableRowsComponent={ExpandedComponent}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
