import React, { useContext, useRef, useState, useEffect } from 'react'
import { AppContext } from 'contexts/AppContext';
import { InfinitySpin } from 'react-loader-spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';

const MySwal = withReactContent(Swal);

export default ({ baseUrl }) => {
    const { context, setContext } = useContext(AppContext);

    const [configs, setConfigs] = useState([]);
    const txtNewValue = useRef();

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Value',
            selector: row => row.value,
            sortable: true
        },
        {
            name: 'Actions',
            selector: row => (<button className='btn btn-secondary' onClick={() => openModalChangeConfig(row.id, row.name, row.value)}><i className='fa fa-pencil'></i></button>)
        }
    ];

    useEffect(() => {
        refreshConfigs();
    }, []);

    function refreshConfigs() {
        const token = context.token;

        fetch(baseUrl + 'configuration', { method: 'GET', headers: { 'x-app-token': token } })
            .then(result => {

                if (result.status == 200)
                    return result.json()
                else
                    throw `Error obtaining configurations: ${JSON.stringify(result)}`;
            })
            .then(result => {

                if (!result?.data)
                    throw `Error obtaining configurations: ${JSON.stringify(result)}`;

                setConfigs(result?.data);
            })
            .catch(err => {
                console.log(err);
                MySwal.fire({
                    icon: 'error',
                    html: 'Error obtaining configurations'
                });

                return;
            });
    }

    function openModalChangeConfig(idConfig, configName, currentValue) {
        let html = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4><i className='fa fa-cog'></i>&nbsp;&nbsp;Change configuration</h4><br />
                <label>Name:</label>
                <input className='form-control' disabled="disabled" value={configName} />
                <br />
                <label>Current Value:</label>
                <input className='form-control' disabled="disabled" value={currentValue} />
                <br />
                <label>New Value:</label>
                <input className='form-control' type='text' ref={txtNewValue} />
                <br /><br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className='btn btn-primary' onClick={() => postSaveConfig(idConfig)} >Salvar</button>
                </div>
            </div>
        );

        MySwal.fire({
            html: html,
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    function postSaveConfig(idConfig) {
        setContext(curr => ({ ...curr, isLoading: true }));

        MySwal.close();

        let error = "";

        const newValue = txtNewValue?.current?.value;

        if (!idConfig)
            error += "Error retrieving the config ID!<br/>";

        if (!newValue)
            error += "The field \"New Value\" is necessary!";

        if (!!error) {

            MySwal.fire({
                icon: 'error',
                html: error
            });

            return;
        }

        const token = context.token;

        fetch(baseUrl + 'configuration', {
            method: 'POST',
            body: JSON.stringify({ idConfig: idConfig, newValue: newValue }),
            headers: { 'x-app-token': token, 'Content-Type': 'application/json' }
        }).then(result => {
            if (result.status == 200)
                refreshConfigs();
            else
                throw `Error updating the configuration`;
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                html: 'Error updating the configuration'
            });
        }).finally(() => {
            setContext(curr => ({ ...curr, isLoading: false }));
        });
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-cog"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Configuration</h1>
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: '0' }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable columns={columns}
                                    data={configs} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
