import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from '../Login/Login';
import { AppContext } from 'contexts/AppContext';

import Layout from '../Layouts/Layout';
import RemoteBas from '../Bas/RemoteBas';
import SlotsSwitcher from 'components/Slots/SlotsSwitcher';

import LayoutNoLogin from '../Layouts/LayoutNoLogin';
import OtpCode from '../OtpCode/OtpCode';
import { InfinitySpin } from 'react-loader-spinner';
import Ticketmaster from 'components/Ticketmaster/Ticketmaster';
import Users from 'components/Users/Users';
import InsertData from 'components/InsertData/InsertData';
import Configuration from 'components/Configuration/Configuration';

function App() {
  const { context, setContext } = useContext(AppContext);

  const checkToken = () => {
    if (!context.token) {
      setContext(old => { return { ...old, isLoading: false } });
      return;
    }

    fetch(baseUrl + 'session', { method: 'GET', headers: { 'x-app-token': context.token } })
      .then((response) => {
        if (!response.ok) {
          setContext(old => { return { ...old, token: null, isLoading: false } });
        }

        setContext(old => { return { ...old, isLoading: false } });
      }).catch(() => {
        setContext(old => { return { ...old, token: null, isLoading: false } });
      });
  };

  useEffect(() => {
    setContext(old => { return { ...old, isLoading: true } });
    checkToken();
  }, []);


  const baseUrl = 'https://api.tixtext.com/';

  const endpoint = window.location.href;

  const isAdmin = context.profiles?.some(x => x == 'administrator');

  if (endpoint.includes('/email/otpcode')) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/email/otpcode" element={
            <LayoutNoLogin childEl={
              <OtpCode baseUrl={baseUrl} />
            } />
          } />
        </Routes>
      </BrowserRouter>
    );
  } else if (context.isLoading) {
    return (<>
      <div style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <InfinitySpin width='200' color="#37b620" visible={false} />
      </div>
    </>);
  }

  if (!context.token) {
    return <Login setContext={setContext} baseUrl={baseUrl} />
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <Layout profile={context.profiles} />
          } />

          {context.profiles?.some(x => x == 'stagefront') || isAdmin ?
            <Route path="/ticketmaster" element={
              <Layout childEl={<Ticketmaster baseUrl={baseUrl} />} />
            } />
            :
            <></>}

          {context.profiles?.some(x => x == 'external') || isAdmin ?
            <>
              <Route path="/bas" element={
                <Layout childEl={<RemoteBas baseUrl={baseUrl} />} />
              } />

              <Route path="/slots" element={
                <Layout childEl={<SlotsSwitcher baseUrl={baseUrl} />} />
              } />

              <Route path="/bas/data" element={
                <Layout childEl={<InsertData baseUrl={baseUrl} />} />
              } />
            </> : <></>
          }

          {isAdmin ?
            <>
              <Route path="/users" element={
                <Layout childEl={<Users baseUrl={baseUrl} />} />
              } />
              <Route path="/configuration" element={
                <Layout childEl={<Configuration baseUrl={baseUrl} />} />
              } />
            </> : <></>
          }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;