import React, { useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';

export default ({ baseUrl }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [emailObj, setEmailObj] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const emailCode = searchParams.get('code');

    let refreshingInterval = {};

    function refreshCode() {
        if (!!isRefreshing)
            return;

        setIsRefreshing(true);

        setTimeout(() => {
            setIsRefreshing(false);
            clearInterval(refreshingInterval);
        }, 120_000);

        refreshingInterval = setInterval(async () => {
            getEmailCode().then((result) => {
                if (result && result.messages && result.messages.length > 0)
                    setEmailObj(result);
            }).catch((error) => {
                console.log(error)
            });
        }, 5000);
    }

    function getEmailCode() {
        return new Promise((resolve, reject) => {

            fetch(baseUrl + 'email/otpcode', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: emailCode })
            }).then(result => {
                if (!result)
                    return null;

                return result.json()
            }).then(result => {
                if (!result)
                    return;

                let otpCode = '';

                let messages = result.messages;

                result.messages = messages.map(x => {

                    if (x.subject.includes('request to reset password')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didntasktochangeyourpassword'));
                    } else if (x.subject.includes('Your Authentication Code')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        console.log(otpCode);

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didn’tRequestaCode?'));
                    }

                    let formattedDate = new Date(x.date).toLocaleString();

                    return { ...x, code: otpCode, formattedDate };
                });

                return resolve(result);
            }).catch((error) => {
                return reject(error);
            });
        });
    }

    useEffect(() => {
        fetch(baseUrl + 'email/otpcode', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: emailCode })
        })
            .then(result => result.json())
            .then(result => {
                if (!result)
                    return;

                let otpCode = '';

                let messages = result.messages;

                result.messages = messages.map(x => {

                    if (x.subject.includes('request to reset password')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didntasktochangeyourpassword'));
                    } else if (x.subject.includes('Your Authentication Code')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        console.log(otpCode);

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didn’tRequestaCode?'));
                    }

                    let formattedDate = new Date(x.date).toLocaleString();

                    return { ...x, code: otpCode, formattedDate };
                });

                setEmailObj(result);

                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
    }, [])

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !isLoading ? '' : 'none' }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: '0' }}>
                        <div className="card-header py-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <h6 className="font-weight-bold text-success" style={{ display: 'inline', marginRight: '10px' }}>
                                        Email to:</h6>
                                    {emailObj.emailAddress}
                                </div>

                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row" style={{ marginBottom: '10px' }}>
                                <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                    • Your Ticketmaster Authentication Code will appear here for VWR entry, Password Changes, and Ticket Transfers.
                                </span>
                            </div>

                            <div className="row" style={{ marginBottom: '10px' }}>
                                <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                    • Be sure to select 'Send me an email' on the Ticketmaster - Authenticate Your Account page.
                                </span>
                            </div>

                            <div className="row" style={{ marginBottom: '10px' }}>
                                <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                    • Click 'Check for Code' button once.  Page will automatically refresh for 2 minutes.
                                </span>
                            </div>
                            <button disabled={!isRefreshing ? false : true} onClick={() => refreshCode()} className='btn btn-success' id='btnRefreshCode'>
                                {!isRefreshing ?
                                    <><i className='fas fa-arrows-rotate'></i> Check for code</> :
                                    <><i className='fas fa-arrows-rotate'></i> Checking...</>
                                }

                            </button>
                        </div>
                    </div>
                </div>
                {!emailObj.messages || emailObj.messages.length == 0 ? '' : emailObj.messages.map((x, i) => {
                    return (
                        <div className="row" key={i}>
                            <div className="card shadow" style={{ padding: '0' }}>
                                <div className="card-body">
                                    <div className="row" style={{ marginBottom: '10px' }}>
                                        <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                            Time Received:
                                        </span>
                                        {x.formattedDate}
                                    </div>
                                    <div className="row" style={{ marginBottom: '10px' }}>
                                        <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                            Subject:
                                        </span>
                                        {x.subject}
                                    </div>

                                    <div className="row" style={{ marginBottom: '10px' }}>
                                        <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                            Your Code:
                                        </span>
                                        {x.code}
                                    </div>
                                </div>
                            </div>
                        </div>);
                })}

            </div>
        </>
    );
}
