import { AppContext } from 'contexts/AppContext';
import React, { useContext, useRef, useState, useEffect } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';
import Select from 'react-select'

import './users.css';
import ImportCsv from 'components/ImportCsv/ImportCsv';

const MySwal = withReactContent(Swal);

export default ({ baseUrl }) => {
    const { context, setContext } = useContext(AppContext);

    const [listUsers, setListUsers] = useState([]);
    const [firstTime, setFirstTime] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [filter, setFilter] = useState('');

    const inputShowPassword = useRef();
    const txtUsername = useRef();
    const txtIpUser = useRef();
    const txtPassword = useRef();

    const objAssignSlots = {
        user: useRef(),
        modem: useRef(),
        port: useRef(),
        slot: useRef(),
        phoneNumber: useRef()
    };

    const columns = [
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Modem',
            selector: row => row.modem,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port_number,
            sortable: true
        },
        {
            name: 'Slot',
            selector: row => row.slot_number,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.phone_number,
            sortable: true
        },
        {
            name: 'Actions',
            selector: row => (<button className='btn btn-danger' onClick={() => deleteSlot(row.id)}><i className='fa fa-trash'></i></button>)
        }
    ];

    useEffect(() => {
        if (firstTime) {
            setFirstTime(false);

            refreshUserList();
        }
    }, []);

    function refreshUserList() {
        fetch(baseUrl + 'users/slots', {
            method: 'GET',
            headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' }
        }).then(result => result.json())
            .then(result => {
                setListUsers(result.slotsList);
            })
            .catch(err => {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error obtaining users list'
                });
            })
            .finally(() => setContext(old => { return { ...old, isLoading: false } }));
    }

    function deleteSlot(idSlot) {
        setContext(old => ({ ...old, isLoading: true }));

        fetch(baseUrl + 'users/slots/' + idSlot, {
            method: 'DELETE', headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' }
        }).then(result => {
            if (result.status == 200) {
                MySwal.fire({
                    icon: 'success',
                    html: 'Slot has been deleted successfully'
                });

            } else {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error deleting slot'
                });
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                html: 'Error deleting slot'
            });
        }).finally(() => {
            setContext(old => ({ ...old, isLoading: false }));
        });
    }

    function openModalCreateUser() {
        let html = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4><i className='fa fa-user'></i>&nbsp;&nbsp;New user</h4><br />
                <label>Server IP:</label>
                <input className='form-control' ref={txtIpUser} />
                <br />
                <label>Username:</label>
                <input className='form-control' ref={txtUsername} />
                <br />
                <label>Password:</label>
                <input className='form-control'
                    type='password'
                    ref={txtPassword} />
                <br /><br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button ref={inputShowPassword} style={{ marginRight: '10px' }} className='btn btn-secondary'
                        onClick={() => {
                            txtPassword.current.type = txtPassword.current.type == 'text' ? 'password' : 'text';
                            inputShowPassword.current.innerHTML = txtPassword?.current?.type == 'password' ? 'Show Password' : 'Hide Password';
                        }}>
                        {'Show Password'}
                    </button>
                    <button className='btn btn-primary' onClick={() => postSaveUser()} >Salvar</button>
                </div>
            </div>
        );

        MySwal.fire({
            html: html,
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    function postSaveUser() {
        setContext(old => ({ ...old, isLoading: true }));

        MySwal.close();

        let username = txtUsername.current.value;
        let password = txtPassword.current.value;
        let ipUser = txtIpUser.current.value;


        if (!username) {
            Swal.fire({
                icon: 'error',
                html: 'username is required'
            });

            setContext(old => ({ ...old, isLoading: false }));
            return;
        }

        if (!ipUser) {
            Swal.fire({
                icon: 'error',
                html: 'Server IP is required'
            });

            setContext(old => ({ ...old, isLoading: false }));
            return;
        }


        if (!password) {
            Swal.fire({
                icon: 'error',
                html: 'password is required'
            });

            setContext(old => ({ ...old, isLoading: false }));
            return;
        }

        var regexIp = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;

        var t = ipUser.match(regexIp);
        ipUser = t[0];

        fetch(baseUrl + 'users', {
            method: 'POST', headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password, ipUser })
        }).then(result => {
            if (result.status == 200) {
                refreshUserList();

                MySwal.fire({
                    icon: 'success',
                    html: 'User has been created successfully'
                });
                setContext(old => ({ ...old, isLoading: false }));
            } else {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error creating new user'
                });
                setContext(old => ({ ...old, isLoading: false }));
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                html: 'Error creating new user'
            });

            setContext(old => ({ ...old, isLoading: false }));
        });
    }

    function openModalAssignSlots() {
        setContext(old => ({ ...old, isLoading: true }));

        fetch(baseUrl + 'users', {
            method: 'GET', headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' }
        }).then(result => result.json())
            .then(result => {
                let options = result.usersList.map(x => ({ value: x.id, label: x.username }));

                let modems = [
                    { value: 'A', label: 'A' },
                    { value: 'B', label: 'B' },
                    { value: 'C', label: 'C' },
                    { value: 'D', label: 'D' },
                    { value: 'E', label: 'E' },
                    { value: 'F', label: 'F' },
                    { value: 'G', label: 'G' },
                    { value: 'H', label: 'H' },
                    { value: 'I', label: 'I' },
                    { value: 'J', label: 'J' },
                    { value: 'K', label: 'K' },
                    { value: 'L', label: 'L' },
                    { value: 'M', label: 'M' },
                    { value: 'N', label: 'N' },
                    { value: 'O', label: 'O' },
                    { value: 'P', label: 'P' },
                    { value: 'Q', label: 'Q' },
                    { value: 'R', label: 'R' },
                    { value: 'S', label: 'S' },
                    { value: 'T', label: 'T' },
                    { value: 'U', label: 'U' },
                    { value: 'V', label: 'V' },
                    { value: 'W', label: 'W' },
                    { value: 'X', label: 'X' },
                    { value: 'Y', label: 'Y' },
                    { value: 'Z', label: 'Z' }
                ];

                let ports = [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' },
                    { value: '10', label: '10' },
                    { value: '11', label: '11' },
                    { value: '12', label: '12' },
                    { value: '13', label: '13' },
                    { value: '14', label: '14' },
                    { value: '15', label: '15' },
                    { value: '16', label: '16' },
                    { value: '17', label: '17' },
                    { value: '18', label: '18' },
                    { value: '19', label: '19' },
                    { value: '20', label: '20' },
                    { value: '21', label: '21' },
                    { value: '22', label: '22' },
                    { value: '23', label: '23' },
                    { value: '24', label: '24' },
                    { value: '25', label: '25' },
                    { value: '26', label: '26' },
                    { value: '27', label: '27' },
                    { value: '28', label: '28' },
                    { value: '29', label: '29' },
                    { value: '30', label: '30' },
                    { value: '31', label: '31' },
                    { value: '32', label: '32' },
                    { value: '33', label: '33' },
                    { value: '34', label: '34' },
                    { value: '35', label: '35' },
                    { value: '36', label: '36' },
                    { value: '37', label: '37' },
                    { value: '38', label: '38' },
                    { value: '39', label: '39' },
                    { value: '40', label: '40' },
                    { value: '41', label: '41' },
                    { value: '42', label: '42' },
                    { value: '43', label: '43' },
                    { value: '44', label: '44' },
                    { value: '45', label: '45' },
                    { value: '46', label: '46' },
                    { value: '47', label: '47' },
                    { value: '48', label: '48' },
                    { value: '49', label: '49' },
                    { value: '50', label: '50' },
                    { value: '51', label: '51' },
                    { value: '52', label: '52' },
                    { value: '53', label: '53' },
                    { value: '54', label: '54' },
                    { value: '55', label: '55' },
                    { value: '56', label: '56' },
                    { value: '57', label: '57' },
                    { value: '58', label: '58' },
                    { value: '59', label: '59' },
                    { value: '60', label: '60' },
                    { value: '61', label: '61' },
                    { value: '62', label: '62' },
                    { value: '63', label: '63' },
                    { value: '64', label: '64' }
                ];

                let slots = [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' }
                ];

                let html = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h4><i className='fa fa-tasks'></i>&nbsp;&nbsp;Assign Slots</h4><br />
                        <div className='row'>
                            <label>User:</label><br />
                            <Select options={options} ref={objAssignSlots.user} /><br />
                        </div>
                        <div className='row mt-2'>
                            <div className='col-sm-6'>
                                <label>Modem:</label>
                            </div>
                            <div className='col-sm-6'>
                                <label>Port:</label>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <Select options={modems} ref={objAssignSlots.modem} />
                            </div>

                            <div>
                                <Select options={ports} ref={objAssignSlots.port} />
                            </div>
                        </div>
                        <label>Slot:</label>
                        <Select options={slots} ref={objAssignSlots.slot} />
                        <br />
                        <label>Phone Number:</label>
                        <input className='form-control' ref={objAssignSlots.phoneNumber} style={{ textAlign: 'center' }}
                            type='number' pattern="^[0-9]$"
                            onKeyDown={(e) => {
                                if (['+', 'e', 'E', ',', '.', '-', 'ArrowUp', 'ArrowDown'].includes(e.key))
                                    e.preventDefault();
                            }} />
                        <br /><br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className='btn btn-primary' onClick={() => postAssignSlots()} >Salvar</button>
                        </div>
                    </div>
                );

                MySwal.fire({
                    html: html,
                    showCancelButton: false,
                    showConfirmButton: false
                });
            })
            .catch(err => {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error getting users list'
                });

                setContext(old => ({ ...old, isLoading: false }));
            })
            .finally(() => {
                setContext(old => ({ ...old, isLoading: false }));
            })

    }

    function postAssignSlots() {
        MySwal.close();

        setContext(old => ({ ...old, isLoading: true }));

        let idAccount = objAssignSlots.user?.current?.getValue();

        if (idAccount) idAccount = idAccount[0].value;
        else {
            Swal.fire({
                icon: 'error',
                html: 'user is required'
            });

            return;
        }

        let modem = objAssignSlots.modem?.current?.getValue();

        if (modem) modem = modem[0].value;
        else {
            Swal.fire({
                icon: 'error',
                html: 'modem is required'
            });

            return;
        }

        let portNumber = objAssignSlots.port?.current?.getValue();

        if (portNumber) portNumber = portNumber[0].value;
        else {
            Swal.fire({
                icon: 'error',
                html: 'port is required'
            });

            return;
        }

        let slotNumber = objAssignSlots.slot?.current?.getValue();

        if (slotNumber) slotNumber = slotNumber[0].value;
        else {
            Swal.fire({
                icon: 'error',
                html: 'slot is required'
            });

            return;
        }

        let phoneNumber = objAssignSlots.phoneNumber?.current.value;

        if (!phoneNumber) {
            Swal.fire({
                icon: 'error',
                html: 'phone is required'
            });

            return;
        }

        fetch(baseUrl + 'users/slots', {
            method: 'POST', headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
            body: JSON.stringify({ idAccount, slotNumber, portNumber, modem, phoneNumber })
        }).then(result => {
            if (result.status == 200) {
                refreshUserList();

                MySwal.fire({
                    icon: 'success',
                    html: 'Slot has been assigned successfully'
                });

            } else if (result.status == 409) {
                MySwal.fire({
                    icon: 'error',
                    html: 'Slot already assigned to another user'
                });
            }
            else {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error assigning the slot'
                });
            }

            setContext(old => ({ ...old, isLoading: false }));
        });
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-user"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Users</h1>
            </div>

            <div style={{ display: 'flex' }}>

                <div className='col-sm-3'>
                    <input type='text' id='txtSearchSlots' placeholder='Filter' onChange={(e) => setFilter(e.currentTarget.value)} />
                </div>

                <div className='col-sm-9 d-sm-flex justify-content-end align-items-center mb-4'>
                    <ImportCsv baseUrl={baseUrl} apiSendFile='users/csv' classListButton='btn btn-success mr-2' classListIcon='fa fa-upload mr-2' btnText='Import CSV' />
                    <button onClick={() => openModalAssignSlots()} className='btn btn-warning mr-2'><i className='fa fa-tasks'></i>&nbsp;Assign Slots</button>
                    <button onClick={() => openModalCreateUser()} className='btn btn-primary'><i className='fa fa-plus'></i>&nbsp;New User</button>
                </div>
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: '0' }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable columns={columns}
                                    data={listUsers.filter(x =>
                                        x.username.includes(filter)
                                        || x.modem.includes(filter)
                                        || x.slot_number.toString().includes(filter)
                                        || x.port_number.toString().includes(filter)
                                        || x.phone_number.includes(filter)
                                    )} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
