import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import logo from '../../img/tixtext.png'
import { AppContext } from 'contexts/AppContext';

import 'css/Login.css';

export default function Login({ baseUrl }) {
  const [iUsername, setIUserName] = useState();
  const [password, setPassword] = useState();

  const [loginError, setLoginError] = useState(false);

  const { context, setContext } = useContext(AppContext);

  const handleSubmit = async e => {
    e.preventDefault();
    setContext(old => { return { ...old, isLoading: true } });

    loginUser(baseUrl, { username: iUsername, password }).then(token => {
      if (token && token.auth == true) {
        setContext(old => { return { ...old, token: token.token, username: iUsername, profiles: (token.profiles || '').split('|') } });

        setLoginError(false);
      } else {
        setContext(old => { return { ...old, token: null, username: null, profiles: [] } });

        setLoginError(true);
      }
    })
      .catch(err => console.log(err))
      .finally(() => {
        setContext(old => { return { ...old, isLoading: false } });
      });
  }

  const loginUser = async (baseUrl, credentials) => {
    return fetch(baseUrl + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }

  return (
    <>
      <div className="limiter">
        <div className="container-login100" id='bgImgCity'>
          <div className="wrap-login100">
            <form onSubmit={handleSubmit} className="login100-form validate-form">
              <span className="login100-form-logo">
                <img src={logo} style={{ width: '25vw' }} />

              </span>

              <span className="login100-form-title mt-3 mb-3">
                Log in
              </span>

              <div style={{ display: !loginError ? 'none' : '', textAlign: 'center', color: 'red', padding: '20px 0' }}>
                <span>Invalid username or password</span>
              </div>

              <div className="wrap-input100 validate-input" data-validate="Enter username">
                <input className="input100" type="text" name="username" placeholder="Username"
                  onChange={e => setIUserName(e.target.value)} />
                <span className="focus-input100" data-placeholder="&#xf207;"></span>
              </div>

              <div className="wrap-input100 validate-input" data-validate="Enter password">
                <input className="input100" type="password" name="pass" placeholder="Password"
                  onChange={e => setPassword(e.target.value)} />
                <span className="focus-input100" data-placeholder="&#xf191;"></span>
              </div>

              <div className="container-login100-form-btn">
                <button type='submit' className="login100-form-btn">
                  Login
                </button>
              </div>

            </form>
          </div>
        </div>

        <footer className='footer-login'>
          © TixText 2023 - All rights reserved
        </footer>
      </div>
    </>
  )
}
