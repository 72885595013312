import { AppContext } from 'contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './ticketmaster.css';

export default ({ baseUrl }) => {
    const emailColumnIndex = "2", sheetId = "1Kz_-fBJMHgvGE1ccVQ9fk-B8iuEjSgwRpryyrJfnhQQ", tabName = "Active Accounts";

    const { context, setContext } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [isRefreshingOtpCode, setIsRefreshingOtpCode] = useState(false);
    const [isRpTaskFinished, setIsRpTaskFinished] = useState(null);

    const [qttErrorsTaskStatus, setQttErrorsTaskStatus] = useState(0);
    const [emailSearched, setEmailSearched] = useState('');
    const [password, setPassword] = useState('');

    const [emailObj, setEmailObj] = useState([]);

    let refreshTaskStatusLoop = null;
    let refreshingInterval = null;

    useEffect(() => {
        Swal.close();

    }, []);

    useEffect(() => {
        if (!!isSearchClicked) {
            setIsLoading(true);

            refreshPassword(true);
            checkResetPasswordTaskStatus(emailSearched, true);

            fetch(baseUrl + 'admin/otpcode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': context.token
                },
                body: JSON.stringify({ email: emailSearched })
            })
                .then(result => result.json())
                .then(result => {
                    if (!result)
                        return;

                    let otpCode = '';

                    let messages = result.messages;

                    result.messages = messages.map(x => {

                        if (x.subject.includes('request to reset password')) {
                            otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                            otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didntasktochangeyourpassword'));
                        } else if (x.subject.includes('Your Authentication Code')) {
                            otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                            otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didn’tRequestaCode?'));
                        }

                        let formattedDate = new Date(x.date).toLocaleString();

                        return { ...x, code: otpCode, formattedDate };
                    });

                    setEmailObj(result);
                }).catch(() => {
                    setIsLoading(false);
                });
        } else {
            setPassword('');
            setEmailSearched('');
            setQttErrorsTaskStatus(0);
            setIsRefreshingOtpCode(false);
            setIsRpTaskFinished(null);
            clearInterval(refreshTaskStatusLoop);
            clearInterval(refreshingInterval);
            setIsLoading(false);
        }
    }, [isSearchClicked])

    useEffect(() => {
        if (!!isRpTaskFinished) {
            refreshPassword();
            setIsRpTaskFinished(null);
        }
    }, [isRpTaskFinished]);

    function refreshCode() {
        if (!!isRefreshingOtpCode)
            return;

        setIsRefreshingOtpCode(true);

        setTimeout(() => {
            setIsRefreshingOtpCode(false);
            clearInterval(refreshingInterval);
        }, 120_000);

        refreshingInterval = setInterval(async () => {
            getEmailCode().then((result) => {
                if (result && result.messages && result.messages.length > 0)
                    setEmailObj(result);
            }).catch((error) => {
                console.log(error)
            });
        }, 5000);
    }

    function getEmailCode() {
        return new Promise((resolve, reject) => {

            fetch(baseUrl + 'admin/otpcode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': context.token
                },
                body: JSON.stringify({ email: emailSearched })
            }).then(result => {
                if (!result)
                    return null;

                return result.json()
            }).then(result => {
                if (!result)
                    return;

                let otpCode = '';

                let messages = result.messages;

                result.messages = messages.map(x => {

                    if (x.subject.includes('request to reset password')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didntasktochangeyourpassword'));
                    } else if (x.subject.includes('Your Authentication Code')) {
                        otpCode = x.message.replace(/[\r]|[\n]|[ ]/g, '');

                        console.log(otpCode);

                        otpCode = otpCode.substring(otpCode.indexOf('HeresyourCode') + 13, otpCode.indexOf('Didn’tRequestaCode?'));
                    }

                    let formattedDate = new Date(x.date).toLocaleString();

                    return { ...x, code: otpCode, formattedDate };
                });

                return resolve(result);
            }).catch((error) => {
                return reject(error);
            });
        });
    }

    function searchGmailDetails(email) {
        setIsLoading(true);
        setIsSearchClicked(true);
    }

    function resetPassword(email) {
        setIsLoading(true);

        if (!email) {
            Swal.fire({
                icon: 'error',
                html: 'Email must be filled'
            });

            setIsLoading(false);

            return;
        }

        let objRequest = {
            textSearched: email,
            columnIndex: emailColumnIndex,
            sheetId,
            tabName
        };

        fetch(baseUrl + 'sheetline', {
            method: 'POST', headers: { 'Content-Type': 'application/json', 'x-app-token': context.token },
            body: JSON.stringify(objRequest)
        })
            .then(result => {
                if (result.status == 200)
                    return result.json();
                else {
                    Swal.fire({
                        icon: 'error',
                        html: 'Error getting the email data'
                    });
                    throw 'Error getting the email data';
                }
            })
            .then(result => {

                if (result && result.data && result.data.rawData && result.data.rowNumber) {

                    Swal.fire({
                        icon: 'question',
                        confirmButtonColor: '#17a673',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonColor: '#e74a3b',
                        cancelButtonText: 'Cancel',
                        reverseButtons: true,
                        title: `Are you sure that you want to reset the password?`
                    }).then(value => {
                        if (value.value) {
                            let objRequest = {
                                "xmlName": 'TMResetPassword',
                                "sheetId": sheetId,
                                "sheetTabName": tabName,
                                "result1Column": 'D',
                                "result2Column": email,
                                "result3Column": null,
                                "result4Column": null,
                                "result5Column": null,
                                "result6Column": null,
                                "result7Column": null,
                                "result8Column": null,
                                "result9Column": null,
                                "result10Column": null,
                                "firstSheetLine": result.data.rowNumber,
                                "lastSheetLine": result.data.rowNumber
                            };


                            fetch(baseUrl + 'insert-tasks',
                                {
                                    method: 'POST', headers: { 'x-app-token': context.token, 'Content-Type': 'application/json' },
                                    body: JSON.stringify({ listTasks: [objRequest] })
                                })
                                .then(result => {
                                    if (result.status == 200) {
                                        Swal.fire({
                                            icon: 'success',
                                            html: 'Task has sent to the queue succesfully!'
                                        });

                                        checkResetPasswordTaskStatus(email);
                                        setIsRpTaskFinished(false);
                                    } else {
                                        throw result.body;
                                    }
                                }).catch((err) => {
                                    Swal.fire({
                                        icon: 'error',
                                        html: 'Error occurred while sending the task to the queue'
                                    });
                                    console.log(err);
                                });
                        }
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: 'No data found for this email'
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    html: 'Error occurred while sending the task to the queue'
                });
                console.log(err);

                setIsLoading(false);
            });
    }

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text);

        Swal.fire({
            toast: true,
            icon: 'success',
            text: 'Text copied to clipboard!',
            timer: 2000,
            position: 'top-right',
            showCloseButton: false,
            showConfirmButton: false
        });
    }

    function checkResetPasswordTaskStatus(email, isCheckOnce) {
        if (isCheckOnce) {
            fetch(baseUrl + 'rp-status', {
                method: 'POST', headers: { 'Content-Type': 'application/json', 'x-app-token': context.token },
                body: JSON.stringify({ email: email })
            })
                .then(result => !!result ? result.json() : null)
                .then(result => {
                    if (result && result.hasOwnProperty('isRunning') && result.hasOwnProperty('isFinished')
                        && !result.isFinished) {
                        setIsRpTaskFinished(false);
                        checkResetPasswordTaskStatus(email);
                    }
                }).catch(() => { });
        } else {
            refreshTaskStatusLoop = setInterval(() => {
                try {
                    fetch(baseUrl + 'rp-status', {
                        method: 'POST', headers: { 'Content-Type': 'application/json', 'x-app-token': context.token },
                        body: JSON.stringify({ email: email })
                    })
                        .then(result => result.json())
                        .then(result => {
                            if (result && result.hasOwnProperty('isRunning') && result.hasOwnProperty('isFinished')) {
                                if (!!result.isFinished) {
                                    setIsRpTaskFinished(true);
                                    clearInterval(refreshTaskStatusLoop);
                                }
                            } else {
                                setIsRpTaskFinished(true);
                                clearInterval(refreshTaskStatusLoop);
                            }
                        });
                } catch (ex) {
                    setQttErrorsTaskStatus(el => ++el);

                    if (qttErrorsTaskStatus >= 5) {
                        setQttErrorsTaskStatus(0);
                        setIsRpTaskFinished(true);
                        clearInterval(refreshTaskStatusLoop);
                    }
                }
            }, 5000);
        }
    }

    function refreshPassword(isInitialSeek, showModal) {
        if (!!isInitialSeek)
            setIsLoading(true);

        fetch(baseUrl + '1tpass?email=' + encodeURIComponent(emailSearched),
            { headers: { 'Content-Type': 'application/json', 'x-app-token': context.token } })
            .then(result => result.json())
            .then(result => {
                if (result && result.output && result.output.length > 0 && result.output[0].account_password) {
                    let oldPassword = password, newPassword = result.output[0].account_password;

                    if (oldPassword != newPassword) {
                        setPassword(result.output[0].account_password);

                        if (!isInitialSeek) {
                            Swal.fire({
                                toast: true,
                                icon: 'success',
                                text: 'Password updated!',
                                timer: 2000,
                                position: 'top-right',
                                showCloseButton: false,
                                showConfirmButton: false
                            });
                        }
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: 'Error obtaining the account password'
                    }).then(() => {
                        setIsSearchClicked(false);
                    });
                }

                if (!!isInitialSeek)
                    setIsLoading(false);
            }).catch(() => {
                if (!!isInitialSeek)
                    setIsLoading(false);
            });
    }

    return (
        <>
            {!!isLoading ? (
                <div style={{ display: 'flex', width: '100%', height: '80vh', justifyContent: 'center', alignContent: 'center' }}>
                    <InfinitySpin width='200' color="#37b620" visible={false} />
                </div>
            ) : (
                <>
                    <div className="d-sm-flex align-items-center mb-4">
                        <i className="fa fa-ticket"></i>&nbsp;&nbsp;
                        <h1 className="h3 mb-0 text-gray-800">Ticketmaster Account Management</h1>
                    </div>

                    {!isSearchClicked ?
                        (<div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="card shadow mb-4" style={{ minHeight: '15em', maxWidth: '60vw' }}>
                                <div className="card-body" style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    padding: '5vw'
                                }}>
                                    <div className='row'>
                                        <input type='text' id='txtGmailSearched' onChange={(e) => setEmailSearched(e.currentTarget.value)}
                                            placeholder='Enter Ticketmaster account email address' />
                                    </div>
                                    <div className='row' style={{ marginTop: '5vh' }}>
                                        <button onClick={() => searchGmailDetails(emailSearched)} className='btn btn-success'>
                                            <i className="fa fa-search"></i> &nbsp;Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        (
                            < >
                                <div >
                                    <div className="card shadow mb-4" style={{ minHeight: '5em' }}>
                                        <div className="card-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>

                                            <button className='btn btn-primary' onClick={() => setIsSearchClicked(false)}>
                                                <i className='fa fa-arrow-left'></i>
                                                &nbsp;Back to search
                                            </button>

                                        </div>
                                    </div>
                                </div>


                                <div className="card shadow" style={{ padding: '0' }}>
                                    <div className="card-header py-3">
                                        <div className='row'>
                                            <div className='col-sm-12 col-lg-12 mt-3'>
                                                <h6 className="font-weight-bold text-success" style={{
                                                    display: 'inline', marginRight: '10px'
                                                }}>
                                                    Email:</h6>
                                                <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(emailSearched)}>
                                                    {emailSearched}
                                                </span>
                                            </div>
                                            <div className='col-sm-12 col-lg-12 mt-3'>
                                                <h6 className="font-weight-bold text-success" style={{
                                                    display: 'inline', marginRight: '10px'
                                                }}>
                                                    Password:</h6>
                                                {isRpTaskFinished === null ?
                                                    (<span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(password)}>{password}</span>) :
                                                    (<span>Resetting Password, please wait</span>)
                                                }

                                            </div>
                                            <div className='col-sm-12 col-lg-12 mt-3'>
                                                {isRpTaskFinished === null ?
                                                    (<button onClick={() => resetPassword(emailSearched)} className='btn btn-danger'>
                                                        <i className='fa fa-key'></i>
                                                        &nbsp;Reset Password
                                                    </button>) :
                                                    (<button disabled='disabled' className='btn btn-danger'>
                                                        <i className='fa fa-key'></i>
                                                        &nbsp;Reseting password...
                                                    </button>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mt-3">
                                            <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                • Your Ticketmaster Authentication Code will appear here for Ticket Transfers.
                                            </span>
                                        </div>
                                        <div className="row mt-3">
                                            <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                • Be sure to select 'Send me an email' on the Ticketmaster - Authenticate Your Account page.
                                            </span>
                                        </div>
                                        <div className="row mt-3">
                                            <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                • Click 'Check for Code' button once.  Page will automatically refresh&nbsp;for&nbsp;2&nbsp;minutes.
                                            </span>
                                        </div>
                                        <div className="mt-3" style={{ marginBottom: '10px' }}>
                                            {!isRefreshingOtpCode ?
                                                (<button onClick={() => refreshCode()} className='btn btn-success' id='btnRefreshCode'>
                                                    <i className='fas fa-arrows-rotate'></i> Check for code
                                                </button>) :
                                                (<button disabled='disabled' onClick={() => refreshCode()}
                                                    className='btn btn-success' id='btnRefreshCode'>
                                                    <i className='fas fa-arrows-rotate'></i> Checking...
                                                </button>)}
                                        </div>
                                    </div>
                                </div>

                                {!emailObj.messages || emailObj.messages.length == 0 ? '' : emailObj.messages.map((x, i) => {
                                    return (
                                        <div key={i} className="card shadow" style={{ padding: '0' }}>
                                            <div className="card-body">
                                                <div className="row" style={{ marginBottom: '10px' }}>
                                                    <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                        Time Received:
                                                    </span>
                                                    {x.formattedDate}
                                                </div>
                                                <div className="row" style={{ marginBottom: '10px' }}>
                                                    <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                        Subject:
                                                    </span>
                                                    {x.subject}
                                                </div>

                                                <div className="row" style={{ marginBottom: '10px' }}>
                                                    <span className="font-weight-bold text-success" style={{ display: 'inline', width: 'auto' }}>
                                                        Your Code:
                                                    </span>
                                                    {x.code}
                                                </div>
                                            </div>
                                        </div>);
                                })}
                            </>
                        )}
                </>)}

        </>
    )
}
