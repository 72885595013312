import { AppContext } from 'contexts/AppContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export default function ImportCsv({ baseUrl, apiSendFile, classListButton, classListIcon, btnText }) {
    const { context, setContext } = useContext(AppContext);

    const [file, setFile] = useState(null);
    const inputHidden = useRef();

    function sendFile(e) {

        setContext(old => ({ ...old, isLoading: true }));

        const file = e.target.files[0];

        clearInputFile(e);

        const data = new FormData();

        data.append(file.name, file, file.name);

        fetch(baseUrl + apiSendFile, {
            method: 'POST', headers: { 'x-app-token': context.token },
            body: data
        }).then(res => res.json)
            .then(data => {
                MySwal.fire({
                    icon: 'success',
                    html: 'File has been uploaded successfully'
                });
            })
            .catch(err => {
                MySwal.fire({
                    icon: 'error',
                    html: 'Error uploading the CSV file'
                });
            })
            .finally(() => {
                setContext(old => ({ ...old, isLoading: false }));
            });
    }

    function clearInputFile(e) {
        try {
            e.target.value = '';

            if (e.target.value) {
                e.target.type = "text";
                e.target.type = "file";
            }
        } catch (e) { }
    }

    return (
        <>
            <input type='file' hidden='hidden' ref={inputHidden} onChange={e => sendFile(e)} />

            <button className={classListButton} onClick={() => inputHidden.current.click()}>
                <i className={classListIcon}></i>{btnText}
            </button>
        </>
    )
}